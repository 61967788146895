




















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})

export default class Succes extends Vue {
  private created (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.$router.push('/signup/terms-policy')
      })
    }
  }
}
